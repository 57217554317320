:root{
  --gray:#ECECEC;
  --darkblue: #090631;
  --black:black;
  --white:white;
  ---purple:#4941A6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* ---------sidbar----------- */
ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidbar {
  width: 260px !important;
  /* height: 586px !important; */
  border-radius: 30px;
  padding-top: 10px;
  /* border: 7px solid var(--darkblue)  ; */
  padding-bottom: 10px !important;

  background-color: var(--gray);
}
.sidbar .sidbarSidbar{
  position: absolute;
  background-image: url(../../assets/icons/sidebar/Vector\ 1.svg);
  background-repeat: no-repeat;
  width: 75px;
  /* height: 81vh !important; */
  margin-top: -20px;
  margin-right: -30px;
  padding-bottom: 40px;
  background-size: contain !important;
}
.sidbar .sidbarSidbar ul{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100px;
}
.sidbar .sidbarSidbar ul li{
 margin-top: 15.5px;
 text-align: center;
 border-radius: 50%;
 width: 30px;
 height: 30px;
 display: flex;
 align-items: center;
 justify-content: center;
 /* display: block; */
}
.sidbar .sidbarSidbar ul .bgIcon{
  background-color: var(--darkblue);
}
.sidbar .sidbarSidbar ul li a{
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidbar .sidbarSidbar ul li a img{
  width: 90%;
  height: 90%;
  border-radius: 50%;
}
.sidbar  .sidbarUl{
  padding-right: 50px;
  width: 108%;
}
.sidbar  ul li {
  padding: 0px;
  text-align: right;
}
.sidbar  ul .sidbarli {
margin-bottom: 11px;
}

.sidbar  ul li .Icon{
font-weight: bold;
 width: 100%;
 height: 34px;
 color: var(--black);
 text-decoration: none;
 display: block;
 padding-right: 19px;
 position: relative;

}
.sidbar  ul li .Id {
background-image: url(../../assets/icons/sidebar/Rectangle\ 6.svg);
background-repeat: no-repeat;
background-position: center;
background-size: cover;
color: var(--white);
padding-top: 3px;
margin-bottom: 0px; 
}
.sidbar  ul li .Id::before {
  content: " ";
  background-color: var(---purple);
  width: 29px;
  height: 10px;
  display: inline-block; /* or block, depending on your layout needs */
  position: absolute;
  left: .5%;
  top: -6px;
  transform: rotate(150deg); /* Adjust the angle as needed */
  z-index: -1;
    }
    .sidbarmed{
      display: none;

    }
    .navbarsidbar{
      background-image: url(../../assets/icons/sidebar/image\ 9.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .navbar-nav-small{
      align-items: end;
      background-color: var(---purple);
      border-radius: 8px;
      margin: 9px;
      padding-right: 15px;
      width: 300px;
    }
  
    .nav-link{
      color: white;
    }
    .nav-link img{
      margin-left: 15px;
    }
    .navbarsidbar{
      display: none;
    }
    @media screen and (max-width: 1150px) and (min-width: 767px) {
      .sidbar{
        display: none;
      }
      .navbarsidbar{
        display: none;
      }
      
      .col-md-3 {
        /* flex: 0 0 100%; */
        width: 10vw;
      }
      .sidbarmed{
        display:block;
        height: 700px;        
      }
      .sidbarmed .sidbarSidbar{
        position: absolute;
  background-image: url(../../assets/icons/sidebar/Vector\ 1.svg);
  background-repeat: no-repeat;
  width: 75px;
  /* height: 81vh !important; */
  margin-top: -20px;
  margin-right: -30px;
  padding-bottom: 30px;
  background-size: contain !important;
      }
      .sidbarmed .sidbarSidbar ul{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width:90px;
        margin-right: 10px;
      }
      .sidbarmed .sidbarSidbar ul li{
       margin-top: 15.5px;
       text-align: center;
       border-radius: 50%;
       width: 30px;
       height: 30px;
       display: flex;
       align-items: center;
       justify-content: center;
       /* display: block; */
      }
      .sidbarmed .sidbarSidbar ul .bgIcon{
        background-color: var(--darkblue);
      }
      .sidbarmed .sidbarSidbar ul li a{
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .sidbarmed .sidbarSidbar ul li a img{
        width: 90%;
        height: 90%;
        border-radius: 50%;
      }
      .sidbarmed  .sidbarUl{
        padding-right: 50px;
        width: 108%;
      }
      .sidbarmed  ul li {
        padding: 0px;
        text-align: right;
      }
      .sidbarmed  ul .sidbarli {
      margin-bottom: 11px;
      }
      
      .sidbarmed  ul li .Icon{
      font-weight: bold;
       width: 100%;
       height: 34px;
       color: var(--black);
       text-decoration: none;
       display: block;
       padding-right: 16px;
       position: relative;
      
      }
      .sidbarmed  ul li .Id {
      background-image: url(../../assets/icons/sidebar/Rectangle\ 6.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      color: var(--white);
      padding-top: 2px;
      }
      .sidbarmed  ul li .Id::before {
        content: " ";
        background-color: var(---purple);
        width: 20px;
        height: 10px;
        display: inline-block; /* or block, depending on your layout needs */
        position: absolute;
        left: .5%;
        top: -6px;
        transform: rotate(150deg); /* Adjust the angle as needed */
        z-index: -1;
          }
      
    }
    @media screen and (max-width: 767px)  {
      .sidbar{
        display: none;
      }
      .navbarsidbar{
       display: block;
     }
     .image,.left_burger,.personal_image{
      display: none;
     }
     #svg_header{
      top: -4px !important;
    }
    .nav-link:hover{
      color: #7c76be !important;
    }
    }