.nextButton {
  height: 9rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.nextButton .MyButton {
  background-color: #c01f59;
  width: 7rem;
  height: auto;
  padding: 5px;
  border: none;
  color: white;
  border-radius: 20px;
}
@media (max-width: 700px) {
  .nextButton {
    height: 9rem;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .nextButton .MyButton {
      background-color: #c01f59;
      width: 7rem;
      height: auto;
      padding: 5px;
      border: none;
      color: white;
      border-radius: 20px;
    }
    .nextButton .MyButton {
      background-color: #c01f59;
      width: 7rem;
      height: auto;
      padding: 5px;
      border: none;
      color: white;
      border-radius: 20px;
    }
  }
  @media (max-width: 420px) {
  }
  .nextButton .MyButton {
    background-color: #c01f59;
    width: 7rem;
    height: auto;
    padding: 5px;
    border: none;
    color: white;
    border-radius: 20px;
  }
}
