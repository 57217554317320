@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.create_tech_acc {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: start;
    background: linear-gradient(180deg, #FFCF6B 0%, #C01F59 213.56%);
}
.createteachacc_pass,.con_createteachacc_pass,.creattchacc_email,.phone_createteachacc-label,.birthdate_create_tech_acc,.create_tech_acc_lastname,.create_tech_acc_firstname{
    font-family: Cairo;
font-size: 13.76px;
font-weight: 600;
/* line-height: 33.29px; */
text-align: right;

}
.header_acc {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.between-borders {
    position: absolute;
    font-size: small;
    text-align: center;
    padding: 0 20px; 
}

.between-borders::before, .between-borders::after {
    content: '';
    position: absolute;
    top: 80%;
    width: 50%;
    height: 3px;
    background-color: #0F0862A1;
}

.between-borders::before {
    right: 100%;
}

.between-borders::after {
    left: 100%;
}

@media (max-width: 576px) {
    .between-borders {
        font-size: smaller;
        padding: 0 10px; 
    }

    .between-borders::before, .between-borders::after {
        width: 50%; 
    }
}

@media (max-width: 768px) {
    .between-borders::before, .between-borders::after {
        width: 30%; 
    }
}

@media (min-width: 1200px) {
    .between-borders::before, .between-borders::after {
        width: 60%; 
    }
}



.create_tech_acc_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 40px; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 40px;
    padding-right: 40px;
    background: transparent;
    border: none;
    box-shadow: none;
    direction: rtl;
    text-align: center;
    background: linear-gradient(180deg, #FFCF6B 0%, #C01F59 213.56%) !important;
    height: 100vh;
}



.card-title {
      text-align: center;
      font-family: 'Cairo', sans-serif;
      font-size: 1.5rem;
      direction: rtl;
  

}



.create_tech_acc_firstname,.create_tech_acc_lastname,.creattchacc_email,.phone_createteachacc-label,.birthdate_create_tech_acc,.createteachacc_pass,.con_createteachacc_pass  {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 5px!important;
    margin-top: 5px!important;
}

.create_tech_acc-form {
    border-radius: 5px;
    font-size: 14px;

}


.p_con_createteachacc_pass, .p_createteachacc_pass ,.p_creattchacc_email ,.p_createteachacc_phone,.p_create_tech_acc_firstname,.p_create_tech_acc_lastname{
    width: 100%;
    height: 40.76px;
    border-radius: 29.61px !important;
    padding-right: 40px !important;
}

.relative1 {
    position: relative;
}

.icon-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


.password-icon {
    right: 10px;
}

.lock-icon {
    left: 10px;
}
.check-icon {
    left: 20px;
}
.phoneIcon,.techIcon,.email-icon{
    right: 10px;
}

.date-input-container {
    display: flex;
    justify-content: space-between;
}


.date-input-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.dropdown-container1 {
    position: relative;
    width: 100%;
}

.dropdownn {
    width: 100%;
    border-radius: 29.61px !important;
}

.iconv
{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.policy-container {
    margin: 1%;
    text-align: right; 
}

.policess {
    display: inline-block; 
    color: #0F0862;
    font-family: Cairo;
    font-size: 14px;
    font-weight: 600;
    line-height: 26.24px;
    text-decoration: none;
    position: relative;
}

.policess::after {
    content: '';
    position: absolute;
    bottom: -5px; 
    left: 0;
    right: 0; 
    height: 2px; 
    background-color: #0F0862; 
}


.create_tech_acc_btn, .back_creat_tech_acc_btn {
    color: white;
    margin-top: 5px;
    border: none !important;
    font-family: Cairo;
font-size: 10.87px!important;
font-weight: 700!important;
}
.create_tech_acc_btn {
    background-color: #0F0862 !important;
    padding: 9.84px 54.47px !important;
    gap: 5.92px !important;
    border-radius: 41.45px !important;
}

.back_creat_tech_acc_btn {
    background-color: #F60909 !important;
    padding: 9.84px 54.47px !important;
    gap: 5.92px !important;
    border-radius: 41.45px !important;

}

.img_tech-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 0;
    height: 100%;
}
.img_tech-container2 {
    height: 100vh;
    display: flex;
flex-direction: column;
    position: relative;
    text-align: center;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.di_1,.di_2{
    text-align: center;
    direction: rtl;
    width: 80%;
    color: #4941A6;
    font-family: Cairo;
    font-size: 12px;
        line-height: 22.49px;
font-weight: 700;
letter-spacing: 0.03em;

}
.techim{
    position: relative;
}
.techim img {
    width: 50%;
    height: auto;
    margin: auto;
}
