.eleButton1 {
  background-color: red; /* Example button color, adjust as needed */
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
}

.DElementTitle {
  color: #ff8a00;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.DElementContent {
  background-color: #1d195d;
  border-radius: 30px;
  padding: 20px;
  width: 390px;
  height: 390px;
}

.DElementBody {
  padding-top: 15px;
  color: #fff;
}

.DElementBody .warning-icon {
  width: 100px;
  margin-bottom: 30px;
}

.DElementBody p {
  margin-bottom: 1rem;
}

.DElementFooter {
  display: flex;
  justify-content: center;
  padding: 20px 20px;
  border-top: none;
  margin-bottom: 20px;
}

.DElementCancel,
.DElementCancel:hover {
  background-color: #cdcdcd;
  color: #000;
  width: 85px;
  height: 35px;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
}
.modal-backdrop {
  width: 0vh;
}

.DElementSave,
.DElementSave:hover {
  background-color: #c01f59;
  color: #fff;
  width: 85px;
  height: 35px;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
}

.DElementDialog {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
}

@media screen and (max-width: 450px) {
  .DElementContent {
    width: 90%;
    padding: 15px;
  }
  .DElementFooter {
    flex-direction: column-reverse;
    align-items: center;
  }
  .DElementCancel,
  .DElementSave {
    width: 50%;
    margin-bottom: 10px;
  }
  .DElementCancel {
    order: 1 !important;
  }
}

@media screen and (max-width: 600px) {
  .DElementContent {
    width: 95%;
    padding: 10px;
  }
  .DElementFooter {
    flex-direction: column;
    align-items: center;
  }
  .DElementCancel,
  .DElementSave {
    width: 100%;
    margin-bottom: 10px;
  }
  .DElementCancel {
    order: 1 !important;
  }
}
