.react-calendar {
  background-color: #4941a6 !important;
  border: 1px #4941a6 solid !important  ;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.react-calendar__month-view__weekdays {
  color: #fe4f60;
}

.react-calendar__navigation {
  color: #ffff !important;
}

.react-calendar__navigation__label__labelText {
  color: #ffff !important;
  font-weight: 800;
}
.react-calendar__month-view__days {
  color: #ffff !important;
}
.modal-backdrop.show {
  opacity: 0;
}
.modal-backdrop {
  width: 0;
  position: absolute !important;
  width: 0;
}
/* .modal-dialog{
  z-index: 66;
  top: 50%;
} */

@media (min-width: 992px) and (max-width: 1199px) {
  .wraber_123 {
    margin: auto;
  }
  .layer_owner {
    width: 18vw !important;
  }
  .layer_friends {
    width: 5vw !important;
  }

  .box_of_book {
    margin-top: 5px;
  }
  .wraber_delete {
    width: 2.5vw !important;
    height: 2.5vw !important;
  }
}

@media (min-width: 768px) and (max-width: 989px) {
  .wraber_123 {
    margin: auto;
  }
  .layer_owner {
    width: 18vw !important;
  }
  .layer_friends {
    width: 5vw !important;
  }

  .box_of_book {
    margin-top: 5px;
  }
  .change_width_in_sm {
    width: 95% !important;
  }
  .wraber_delete {
    width: 3vw !important;
    height: 3vw !important;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .wraber_123 {
    margin: auto;
  }
  .layer_owner {
    width: 18vw !important;
  }
  .layer_friends {
    width: 5vw !important;
  }

  .box_of_book {
    margin-top: 5px;
  }
  .change_width_in_sm {
    width: 95% !important;
  }
  .wraber_delete {
    width: 4vw !important;
    height: 4vw !important;
  }
}

@media (max-width: 599px) {
  .wraber_123 {
    margin: auto;
  }
  .layer_owner {
    width: 30vw !important;
  }
  .layer_friends {
    width: 7vw !important;
  }

  .box_of_book {
    width: 100% !important;
    margin-top: 5px;
  }
  .change_width_in_sm {
    width: 95% !important;
  }
  .wraber_delete {
    width: 6vw !important;
    height: 6vw !important;
  }
}

@media (min-width: 350px) and (max-width: 430px) {
  .wraber_123 {
    margin: auto;
  }
  .layer_owner {
    width: 30vw !important;
  }
  .layer_friends {
    width: 7vw !important;
  }

  .box_of_book {
    width: 100% !important;
    margin-top: 5px;
  }

  .change_width_in_sm {
    width: 95% !important;
  }
  .wraber_delete {
    width: 6vw !important;
    height: 6vw !important;
  }
}
@media (min-width: 576px) {
  .rady {
    max-width: 766px;
    margin: 6.75rem auto;
    max-height: 766px;
  }
}
.new-footer {
  align-items: flex-end;
  height: 82px;
  display: flex;
  justify-content: center;
}
.side_inc_dec {
  background-color: #4941a6 !important;
  color: #ffff;
}
.inputGroupWraping {
  flex-wrap: nowrap !important;
}

.managerFGroup input {
  border-radius: 6px;
}

.managerForm {
  direction: rtl;
}
.parent1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.child1,
.child3 {
  position: relative;
  margin: auto;
  margin-top: 1rem;
  /* width: 45%; */
}

.form-text {
  display: block;
  height: 2rem;
}

.managerBody {
  display: flex;
  flex-direction: column;
}

.managergDialog {
  position: relative;
}

.managerContent {
  width: 790px !important;
  /* height: 30.1875rem;  */
  /* height: 485px !important; */
  background-color: #1d195d;
  color: white;
  position: relative;
}

.managerHeader {
  text-align: center;
  justify-content: center;
  border-color: #a6a0f4;
  width: 100% !important;
}

.managerTitle {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff8a00;
  display: flex;
  justify-content: center;
}

/* .kh {
  position: relative;
  width: 1em;
  height: 1em;
  padding: 0;
  background-color: #FFFFFF;
  color: white; 
  border: none;
  appearance: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
} */

.kh::before,
.kh::after {
  content: "";
  position: absolute;
}

.kh::before {
  transform: rotate(45deg);
}

.kh::after {
  transform: rotate(-45deg);
}

.kh {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ffffff;
  color: red;
  border-radius: 50% !important;
  padding: 0 !important;
}
.kh,
.kh:hover {
  background-color: #ffffff;
}
.managerControl {
  width: 100%;
  padding: 0.625rem;
  border-radius: 0;
  border: none;
  margin-top: 0.625rem;
  background-color: #d9d9d9;
  color: #0e0a43;
}

/* .form-text {
  color: #A6A0F4;
} */
/* .layer_friends{
  background-color: #9F1962;
} */
.managerFooter {
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 33px; */
  padding: 0px;
  margin-bottom: 0px;
  width: 100%;
}

.managerCancel,
.managerCancel:hover,
.managerCancel:active {
  /* background-color: transparent; */
  border: 1px solid #fe4f60 !important;
  color: #fe4f60;
  border-radius: 0.625rem;

  width: 120px !important;
  height: 40px !important;

  font-weight: bold;
  border-radius: 30px !important;
}
.managerButton {
  background-color: #a6a0f4;
  border-radius: 10px;
}
#firstbutt {
  width: 8.75rem;
  height: 2.875rem;
}

#second {
  width: 140px;
  height: 46px;
  background-color: #c01f59;
  border-radius: 30px;
}
.managerSave {
  width: 120px !important;
  height: 40px !important;
  font-weight: bold;
  border-radius: 30px !important;
  border: none;
  background-color: #fe4f60;
  color: white;
  font-weight: bold;
}

.managerSave:hover {
  background-color: #fe4f60;
}

.input_contain_value{
  width: 153px;
}