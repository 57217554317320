.class-info-button-co {
  border: none;

  height: 32.63px;
  background-color: #ff7380;
  border-radius: 32.63px;
  align-items: center;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  width: 134px;
}
.class-info-button-co div {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  /* justify-content: center; */
}

.class-info-button-co p {
  transform: translate(-11px, 6px);
  margin: 0;
  width: fit-content;
}

.class-info-divider {
  width: 84%;
  height: 1px;
  background-color: #ff919c;
}
