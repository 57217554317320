@media (min-width: 576px) {
  .my-mod-add {
    max-width: 766px;
    margin: 6.75rem auto;
    max-height: 766px;
  }
}
.new-footer {
  align-items: flex-end;
  height: 82px;
  display: flex;
  justify-content: center;
}
