@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.Reset_code_page {
    width: 100%;
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
     background: linear-gradient(180deg, #FFCF6B 0%, #C01F59 213.56%);
    

}

.reset_code_btn{
    color: white;
    margin-top: 15px;
    font-size: 14px!important ;
    border: none !important;
    font-family: Cairo;
font-size: 14.87px!important;
font-weight: 700!important;
line-height: 27.86px!important;
background-color: #0F0862 !important;
padding: 11.84px 54.47px 11.84px 54.47px !important;
gap: 5.92px;
border-radius: 41.45px !important;
margin-top: 5rem
}




.reset_code_email{
    font-family: 'Cairo', sans-serif;
     font-size: 17.76px;
     font-weight: 600;
     line-height: 33.29px;
     text-align: right;


    }
   
 .reset1-card {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    border: none;
    box-shadow: none;
    direction: rtl;
    text-align: center;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    background: linear-gradient(180deg, #FFCF6B 0%, #C01F59 213.56%);
    height: 100vh;

}
.reset_code-form{
    padding-top: 6rem;
    width:100%;
}

.card-title2 {
   text-align: center;
   margin-bottom: 20px;
   font-family: 'Cairo', sans-serif;
   font-size: 1.5rem;
   direction: rtl;
   width: 100%;
   margin: 0 auto; 
}
.card-title-l2{
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    
    
}
.reset1-form .form-group {
    margin-bottom: 1.5rem;
    direction: rtl;
    text-align: right;
}

.reset1-form .form-group .form-label {
    font-size: 16px;
    margin-bottom: 0.5rem;
}

.form-label {
    display: flex;
    align-items: center;
}

.p_code {
    width: 100%;
    height: 47.76px;
    border-radius: 29.61px !important;
    padding-right: 40px !important;
    padding-left: 15px;
}
.relative1 {
    position: relative;
}

.icon-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.email-icon {
    right: 10px;
}

.check-icon {
    left: 20px;
}




 

.header1-l2 {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    /* padding: 1rem; */
}

.between-borders1-l2 {
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
        padding: 0 20px; 
}

.between-borders1-l2::before, .between-borders1-l2::after {
    content: '';
    position: absolute;
    top: 50%; 
    width: 50%;
    height: 3px;
    background-color: #0F0862A1;
}

.between-borders1-l2::before {
    right: 100%;
}

.between-borders1-l2::after {
    left: 100%;
}

@media (max-width: 576px) {
    .between-borders1-l2 {
        font-size:  24px;
        padding: 0 10px; 
    }

    .between-borders1-l2::before, .between-borders1-l2::after {
        width: 40%; 
    }
}

@media (max-width: 768px) {
    .between-borders1-l2::before, .between-borders1-l2::after {
        width:14%; 
    }
}

@media (min-width: 1200px) {
    .between-borders1-l2::before, .between-borders1-l2::after {
        width: 40%; 
    }
}

