.lineButton{
    position: relative;
z-index: 9;
}
.lineButton::before{
    content: "";
    width: 100%;
    height: 2px;
top: 50%;   
    background-color: #FE4F60
    ;
    position: absolute;
    z-index: -1;
}
.bulits {
    list-style-type: square;
    color: #ffff;
}
.stylecheckbox{
    background-color: #FE4F60 !important;
    border:10px solid red !important;
    color:"red"; 
}
.fontsizexam{
        font-weight: bold;
}
/* /////////////////////////////////////// */
.no-click {
    pointer-events: none;
  }
.drocontainer {
    width: 200px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    text-align: center;
    direction: rtl;
}

.question-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.control-button {
    background-color: #d22f5a;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    padding: 4px 9px;
    cursor: pointer;
    border-radius: 4px;
}

.control-button:hover {
    background-color: #b51f49;
}

.countQuestion {
    /* margin: 0 10px; */
    font-size: 1.2rem;
    font-weight: bold;
    width: 30px;
    text-align: center;
}

.labelQuestion {
    flex-grow: 1;
    text-align: right;
    font-size: 0.9rem;
    margin-right: 4px;
}

.total-questions {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid #ccc;
    font-size: 1.1rem;
    font-weight: bold;
    color: #d22f5a;
}
@media screen and (max-width: 600px) {

button.me-3.text-bold{
    font-size:10px;
    padding: px 3px !important;
}
.timerexam{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 60px !important;
    height: 60px !important;
}
}