.exam-container {
    font-family: 'Arial, sans-serif';
    direction: rtl;
    text-align: center;
    /* border: 1px solid rgb(224, 224, 224); */
    border: 1px solid black;
    margin:auto;
    padding: 20px;
  }
  
  .exam-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
    
}

.exam-header .exam-logo img {
    width: 134px;
}

.exam-header .exam-title {
    flex: 1;
    font-size: 24px;
    /* font-weight: bold; */
}
.exam-content{
  border-bottom: 2px;
}
.exam-content .exam-info, .exam-content .exam-details, .exam-content .exam-note ,.exam-question {
    margin-bottom: 20px;
    /* border-bottom: 3px solid black; */
  }
  
  .exam-content .exam-info {
    font-size: 24px;
    /* font-weight: bold; */
    border-bottom:none;

  }
  
  .exam-content .exam-details {
    text-align: left;
    line-height: 1.8;
    font-size:21.33px;
    border-bottom:3px solid;

  }
  
  .exam-content .exam-note {
    font-size:21.33px !important;
    padding: 0px 10px;
    text-align: right;
    border-bottom:none;
  }
  
   .exam-question {
    /* margin-top: 20px; */
    padding: 10px;
    text-align: start;
    font-weight: bolder;
    font-size: 24px;
  }
  
 .exam-answer {
    margin-top:5px;
    padding: 10px  20px;
    /* min-height: 50px; */
    font-size: 14px !important;

  }
  
  .exam-footer {
    margin-top: 20px;
  }
  
  .exam-footer .exam-end, .exam-footer .exam-wish, .exam-footer .exam-teacher {
    margin-bottom: 10px;
    font-size: 21.33px;
  }
  
  .exam-footer .exam-end {
    font-size: 24px;
    font-weight: bold;
  }
/* ////////////////////////////////////////////////////// */
.container-exame {
  overflow-y: auto;
}

.draggable {
  padding: 1rem;
  background-color: transparent;
  /* border: 1px solid black; */
  cursor:grab;
  /* display: inline-block; */
}

.draggable.dragging  {
  opacity: .5;
}
@media print {
  .container-examee {
    page-break-before: always;
  }
}