@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

.acc .s_1 {
    display: block;
    text-align: center;
    font-size: 14px;
    color: black;    
    margin-top: 10px;
    /* margin-bottom: 2rem; */

}

.acc .s_2 {
    cursor: pointer;
    color: #0F0862;
}

.tech_acc, .stud_acc {
    color: white;
    /* margin-top: 10px; */
    margin-top: 15px;
    font-size: 14px!important ;
    border: none !important;
    font-family: Cairo;
font-size: 14.87px!important;
font-weight: 700!important;
line-height: 27.86px!important;
text-align: left!important;
}
.stud_acc {
    background-color: #4941A6 !important;
    padding: 11.84px 54.47px !important;
    gap: 5.92px !important;
    border-radius: 41.45px !important;
}

.tech_acc {
    background-color: #B33E68 !important;
    padding: 11.84px 54.47px !important;
    gap: 5.92px !important;
    border-radius: 41.45px !important;
}


