@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.collContainer{
    height: 100vh;
}
.login-card{
    text-align: center;
    background: linear-gradient(180deg, #FFCF6B 0%, #C01F59 213.56%)!important;   

}
.login-container {
    width: 100%;
    display: flex;
    /* align-items: center; */
    background: linear-gradient(180deg, #FFCF6B 0%, #C01F59 213.56%);   
    height: 100vh;
}
.email,.password{
    font-family: Cairo;
font-size: 17.76px;
font-weight: 600;
line-height: 33.29px;
text-align: right;

}
.header1-l1 {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
    
}

.between-borders1-l1 {
    position: absolute;
    text-align: center;
    padding: 0 20px; 
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
        padding: 0 20px; 
}

.between-borders1-l1::before, .between-borders1-l1::after {
    content: '';
    position: absolute;
    top: 50%; /* يوضع في منتصف ارتفاع العنصر الأب */
    width: 50%;
    height: 3px;
    background-color: #0F0862A1;
}

.between-borders1-l1::before {
    right: 100%;
}

.between-borders1-l1::after {
    left: 100%;
}

@media (max-width: 576px) {
    .between-borders1-l1 {
        font-size: 24px;
        padding: 0 10px; 
    }

    .between-borders1-l1::before, .between-borders1-l1::after {
        width: 40%; 
    }
}

@media (max-width: 768px) {
    .between-borders1-l1::before, .between-borders1-l1::after {
        width:14%; 
    }
}

@media (min-width: 1200px) {
    .between-borders1-l1::before, .between-borders1-l1::after {
        width: 40%; 
    }
}


.rem_login{
    font-family: Cairo;
font-size: 14.21px;
font-weight: 700;
line-height: 26.63px;
text-align: left;
padding-top: 1%;
padding-bottom: 5%;

}
.login-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    background: transparent;
    border: none;
    box-shadow: none;
    direction: rtl;
}

.card-title-l1 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Cairo';
    font-size: 1.5rem;
    direction: rtl;
    font-size: 24px;
    font-weight: 600;
        padding: 0 20px; 
}
.login_btn {
    color: white;
    /* margin-top: 15px; */
    font-size: 14px!important ;
    border: none !important;
    font-family: Cairo;
font-size: 14.87px!important;
font-weight: 700!important;
line-height: 27.86px!important;
background-color: #0F0862 !important;
padding: 11.84px 54.47px 11.84px 54.47px !important;
gap: 5.92px;
border-radius: 41.45px !important;

}




.login-form  {
    font-size: 16px;
    margin-bottom: .5rem;
    direction: rtl;
}

.email ,.password  {
    display: flex;
    margin-bottom: .5rem;
    align-items: center;
}

.login-form .p_email,.p_paas {
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
}

.form-check-label {
    font-size: 14px;
    padding-top: 5%;

    padding-bottom: 5%;

}

.forgot-password {
    font-size: 14px;
    color: black;
    font-family: 'Cairo', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    direction: rtl;
    text-decoration: none;  
    padding-top: 1%;
    padding-bottom: 3%;
}



.p_pass, .p_email {
    width: 100%; 
    height: 47.76px;
    border-radius: 29.61px !important;
    padding-right: 40px !important;
}

.relative1 {
    position: relative;
}

.icon-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.email-icon {
    right: 10px;
}

.check-icon {
    left: 20px;
}

.password-icon {
    right: 10px;
}

.lock-icon {
    left: 10px;
}
.form-switch .form-check-input {
    /* background-image: url('../assets/Rectangle4199.svg?v=1');  */

    width: 3em;
    margin-left: -4.5em;
   
    margin-top: 10px;
}
.green-bg {
    background-color: rgba(172, 234, 223, 0.3) !important;
   
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: black !important;
}