.notification {
  width: 100%;
  flex-direction: rtl;
}

.header-notify {
  height: 7.3rem;
}
hr {
  background-color: #a6a0f4;
  margin-top: 0px;
  margin-bottom: 13px;
  height: 2px;
}
.header-notify img {
  width: 2rem;
}

.header-notify h4 {
  margin-bottom: 0;

  font-weight: 700;
}
.notify-ico {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.outArrorws {
  height: 5rem;
  font: 1em sans-serif;
}

.parent {
  width: 80%;
  margin: auto;
  height: 60px;
}

.arrows {
  align-items: center;
  width: 34.23px;
  background-color: rgb(0, 12, 22);
  height: 2rem;
}
.icon-notify {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d195d;
  border-radius: 15px 0px 0px 15px;
  width: 3rem;
}
.icon-notify button {
  border-radius: 15px 0px 0px 15px;

  height: 100%;
  border: none;
  color: #ffff;
  background-color: #1d195d;
}
.message {
  height: 3.2rem;
  border-radius: 15px;
  display: flex;
  background-color: #4941a6;
  border-radius: 15px;
}

.checkB {
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-notify {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1rem;
}
@media (max-width: 764px) {
  .header-notify {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1000px) {
  .notify-ico {
    width: 38%;
  }
}

@media (max-width: 410px) {
  .content-notify {
    font-size: 14px;
  }
}

@media (max-width: 380px) {
  .icon-notify {
    width: 2.2rem;
  }
  .checkB {
    width: 2rem;
  }

  .notify-ico {
    width: 59%;
    margin-left: 2px;
  }
  .content-notify {
    font-size: 13px;
    margin-left: 4px;
  }
}

.content-notify p {
  margin: 0;
  width: 95%;
  font-weight: 600;
}

.btttn {
  margin-top: 5px;

  color: #a6a0f4;
  width: 105px;
  height: 30px;
  border: #a6a0f4 solid 1px;
  padding: 0px, 16px, 0px, 16px;
  border-radius: 20px;
}
.bttt {
  color: #ff3a3a;
  width: 105px;
  height: 30px;
  border: #ff3a3a solid 1px;
  border-radius: 20px;
}
