.tr trai {
  border: none !important;
}

.iddd {
  transform: translate(20px, -3px);
}

.to-arr-put {
  transform: translate(38px, -3px);
}

.arrowfour {
  transform: translate(56px, -3px);
}

.arrowfive {
  transform: translate(73px, -3px);
}
@media (max-width: 550px) {
  .iddd {
    transform: translate(14px, -2.7px);
  }

  .to-arr-put {
    transform: translate(32px, -1.7px);
  }

  .arrowfour {
    transform: translate(50px, -0.8px);
  }

  .arrowfive {
    transform: translate(68px, 0px);
  }
}
@media (max-width: 450px) {
  .iddd {
    transform: translate(14px, -3.7px);
  }

  .to-arr-put {
    transform: translate(29px, -2.7px);
  }

  .arrowfour {
    transform: translate(44px, -1.8px);
  }

  .arrowfive {
    transform: translate(60px, -1px);
  }
}
