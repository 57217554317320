.ques {
  clip-path: polygon(24% 26%, 100% 24%, 76% 45%, 101% 66%, 24% 65%, 0 47%);
  width: 9.25rem;
  height: 63.62px;
  padding: 0;
  padding: 0;
  border: none;
  /* transform: translate(5px); */
}
.ques p {
  width: fit-content;
  color: black;
  font-weight: 600;
  font-size: 16px;

  transform: translate(-29px, -5px);
}
/* @media (min-width: 636) {
  .ques {
    clip-path: polygon(27% 28%, 100% 23%, 71% 45%, 102% 66%, 28% 66%, 0 47%);
    width: 7.25rem;
    height: 62.62px;
    padding: 0;
    padding: 0;
    border: none;
    transform: translate(2px);
  }
} */
@media (max-width: 620px) {
  .ques {
    clip-path: polygon(27% 28%, 100% 23%, 71% 45%, 102% 66%, 28% 66%, 0 47%);
    width: 8.25rem;
    height: 62.62px;
    padding: 0;
    padding: 0;
    border: none;
    transform: translate(2px);
  }
}
@media (max-width: 700px) {
  .ques p {
    width: fit-content;
    color: black;
    font-weight: 600;
    font-size: 14px;

    transform: translate(-29px, -5px);
  }
}
@media (max-width: 580px) {
  .ques p {
    width: fit-content;
    color: black;
    font-weight: 500;
    font-size: 11px;
    width: 5rem;
    transform: translate(-29px, -5px);
  }
}

@media (max-width: 555px) {
  .ques {
    clip-path: polygon(24% 26%, 100% 24%, 76% 45%, 101% 66%, 24% 65%, 0 47%);
    width: 7.25rem;
    height: 63.62px;
    padding: 0;
    padding: 0;
    border: none;
    /* transform: translate(5px); */
  }
  .ques p {
    width: fit-content;
    color: black;
    font-weight: 400;
    font-size: 11px;
    width: 3rem;
    transform: translate(-22px, -5px);
  }
}
@media (max-width: 450px) {
  .ques {
    clip-path: polygon(24% 26%, 100% 24%, 76% 45%, 101% 66%, 24% 65%, 0 47%);
    width: 81px;
    height: 63.62px;
    padding: 0;
    padding: 0;
    border: black solid 1px;
    /* transform: translate(5px); */
  }
}
@media (max-width: 370px) {
  .triangle-left p {
    font-size: 9.5px;
  }
  .ques {
    clip-path: polygon(24% 26%, 100% 24%, 76% 45%, 101% 66%, 27% 65%, 5 47%);
    width: 74px;
    height: 63.62px;
    padding: 0;
    padding: 0;
    border: none;
    /* transform: translate(5px); */
  }
}
