
 @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

 * {
     padding: 0;
     margin: 0;
     box-sizing: border-box;
 }
 .reset1-form{
    padding-top: 3rem;
    width: 100%;
 }
 .req_code_container {
     width: 100%;
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
     background: linear-gradient(180deg, #FFCF6B 0%, #C01F59 213.56%);
 }
 .req_code_btn {
    color: white;
    margin-top: 15px;
    font-size: 14px!important ;
    border: none !important;
    font-family: Cairo;
font-size: 14.87px!important;
font-weight: 700!important;
line-height: 27.86px!important;
background-color: #0F0862 !important;
padding: 11.84px 54.47px 11.84px 54.47px !important;
gap: 5.92px;
border-radius: 41.45px !important;
margin-top: 5rem
    
   }
   .p_email_reset_page_1{
    width: 100%;
    height: 47.76px;
    border-radius: 29.61px !important;
    padding-right: 40px !important;
    padding-left: 15px;
   }
   
   .req_code_email {
    font-family: 'Cairo';
    font-size: 17.76px;
    font-weight: 600;
    line-height: 33.29px;
    text-align: right;

    padding-bottom: 5%;
}


 .reset1-card {
     display: flex;
     flex-direction: column;
     justify-content: start;
     align-items: center;
     font-size: 24px;
     background: transparent;
     border: none;
     box-shadow: none;
     direction: rtl;
     text-align: center;
     /* padding: 40px; */
     padding-top: 40px;
     background: linear-gradient(180deg, #FFCF6B 0%, #C01F59 213.56%);
     height: 100vh;
     padding-right: 40px;
     padding-left: 40px;
 }

 .card-title-l2 {
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    

}

 
 
 
 .reset1-form {
     direction: rtl;
     text-align: center;
 }
 
 .reset1-form  {
     font-size: 16px;
 }
 
 .relative1 {
     position: relative;
 }
 
 .icon-container {
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
 }
 
 .email-icon {
     right: 10px;
 }
 
 .check-icon {
     left: 20px;
 }
 
 
 
.header1-l {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    /* padding: 1rem; */
}

.between-borders1-l {
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
        padding: 0 20px; 
}

.between-borders1-l::before, .between-borders1-l::after {
    content: '';
    position: absolute;
    top: 50%; 
    width: 50%;
    height: 3px;
    background-color: #0F0862A1;
}

.between-borders1-l::before {
    right: 100%;
}

.between-borders1-l::after {
    left: 100%;
}

@media (max-width: 576px) {
    .between-borders1-l {
        font-size:  24px;
        padding: 0 10px; 
    }

    .between-borders1-l::before, .between-borders1-l::after {
        width: 40%; 
    }
}

@media (max-width: 768px) {
    .between-borders1-l::before, .between-borders1-l::after {
        width:14%; 
    }
}

@media (min-width: 1200px) {
    .between-borders1-l::before, .between-borders1-l::after {
        width: 40%; 
    }
}
