
.react-calendar__tile--now{
    background-color: #0E0A43;
    border-radius: 45%;

}
.stylemodale{
        width: 238px !important; 
        /* align-items: center !important; */

}   
.react-calendar__tile{
    color: aliceblue;
}
.shadow-boxxx {
box-shadow: 0px -3px 10px rgba(0, 0, 0, .6); /* Shadow definition */
background-repeat: no-repeat;
background-position: center;
margin: px px;
font-size: 11px;
padding-top: 10px;
text-align: center;
/* width: 10rem; */
height: 8rem;
margin-bottom: 10px;
}
.Bold{
    font-weight: bold;
}
.boxday{
    background-image: url(../../../assets/icons/home_student_view/undraw_social_update_re_xhjr\ 1.svg);
    background-repeat: no-repeat;
    background-position:bottom;
    /* background-color: aqua; */
}
.boximgbackground1{
background-image: url(../../../assets/icons/home_student_view/Group\ 325.svg);
background-position: center;
background-size: cover;
}

.boximgbackground3{
background-image: url(../../../assets/icons/home_student_view/Group\ 327.svg);
background-position: center;
background-size: cover;
}
@media screen and (max-width:450px) {
    .rowHomestudent{
        display: flex !important;
        width: 100% !important;
    }
    .Bold{
        font-weight: bold;
    }
    .boxday{
        background-image: url(../../../assets/icons/home_student_view/undraw_social_update_re_xhjr\ 1.svg);
        background-repeat: no-repeat;
        background-position:bottom;
        /* background-color: aqua; */
    }
    .modaleSize{
        width: 320px; 
        align-items: center;
        margin-right: 13%;
    }
    
    @media screen and (max-width:450px) {
        .rowHomestudent{
            display: flex !important;
            width: 100% !important;
        }
        .rowHomestudent .child{
          margin-top: 10px !important;
        }
        .rowHomestudent .col-3{
            width: 100% !important;
            margin: 10px 0px;
        }
        .homeStudentcalender{
    
            padding-top: 10px;
        }
        .child{
            margin-top: 10px !important;
        }
        .calenderhomestudent{
            width: 90%;
            display: flex;
     justify-content: center;
            align-items: center;
            
        }
    
    }}
    /* //////////////////////////////////////////// */
    .honor-board {
        background-color: #2c2c6c;
        /* padding: 20px; */
        border-radius: 15px;
        color: white;
    }
    
    .header {
        border-bottom: 2px solid #f6c700;
        padding-bottom: 10px;
    }
    
    .subject-buttons .btn {
        margin: 0 5px;
    }
    
    .subject-buttons .btn-primary {
        background-color: #f6c700;
        border: none;
    }
    
    .subject-buttons .btn-secondary {
        background-color: #595a8e;
        border: none;
    }
    
    .student-card {
        /* background-color: #3e3e84; */
        border-radius: 15px;
        position: relative;
        width: 7rem;
    }
    
    .student-img {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
    }
    
    .student-info {
        margin-bottom: 10px;
        
    }
    
    .rank-badge {
        position: absolute;
        top: 66px;
        left: 35px;
        background-color: #595a8e;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        color: #FF8A65 ;
        font-size: .8em;
        font-weight: bold;
        /* color: #2c2c6c; */
        background-image: url(./Group.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 17px;

    }
    
    .rank-1 {
        background-image: url(./Group\ copy.svg);
    }
    
    .rank-2 {
        background-image: url(./Group\ copy\ 2.svg);
    }
    
   