.td-show-mod {
  background-color: #4941a6 !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 16px;
}
.td-show-mod2 {
  background-color: #1d195d !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 16px;
}
