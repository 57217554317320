
  .label_size{
    width: 114px !important;
    font-size: 16px;
    font-family: Cairo ;
    font-weight: 600px;
  }
  .personal_inf{
    font-size: 16px;
    font-family: Cairo ;
    font-weight: 600px;
  }
  .input_size{
    width: 20vw !important;
   
  }
  .modaleSize1{
    width: 80% !important;
    height: 85%;
   background-color: #1D195D   !important;
  
  }
  .modaleSize2{
    width: 100% !important;
    /* align-items: center;
    justify-content: center; */
  }
  .close{
    /* background-color: #00B4FF; */
    display: flex;
    /* align-items:start;
    justify-content: start; */
    width: 100%;
    border-bottom: none;

  }
  @media (min-width: 992px) and (max-width: 1100px){
    .button_wraper{
      text-align: center;
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 989px) {
  .input_size{
    width: 50vw !important;
  }
  .top_input_margin{
    margin-top: 12px !important;
  }
  .Wraber_ele{
    margin-top: 10px !important;
  }
  .button_wraper{
    text-align: center;
  }
  
  }
   @media (min-width: 600px) and (max-width: 767px){
    .input_size{
      width: 49vw !important;
    }
    .top_input_margin{
      margin-top: 12px !important;
    }
    .Wraber_ele{
      margin-top: 10px !important;
    }
    .button_wraper{
      text-align: center;
    }
  
  
  } 
  
  @media (max-width: 599px){
    .input_size{
      width: 55vw !important;
    }
    .top_input_margin{
      margin-top: 12px !important;
    }
    .Wraber_ele{
      margin-top: 10px !important;
    }
    .button_wraper{
      text-align: start;
    }
    .modaleSize1{
      width: 90% !important;
     background-color: #1D195D   !important;
    
     
    }
    .close{
      display: flex;
      align-items:center !important;
      justify-content: center !important;
  
    }
    .heightmodal{
      align-items: start !important; 
padding-top: 50px;
    }
  }
  
  @media (min-width: 350px) and (max-width: 430px){
    .input_size{
      width: 79vw !important;
    }
    .top_input_margin{
      margin-top: 12px !important;
    }
    .Wraber_ele{
      margin-top: 10px !important;
    }
    .button_wraper{
      text-align: center;
    }
  
  }
  /* 
  
  .user-img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: fixed;
    justify-content: center;
    align-items: center;
  }
  #photo{
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }
  #file{
    display: none;
  }
  #uploadbtn{
    position: absolute;
    height: 30px;
    width: 30px;
    padding: 6px 6px;
    border-radius: 50%;
    cursor: pointer;
    color: #ffff;
    transform: translateX(-90%);
    background-color: rgb(173, 172, 0,802);
    box-shadow: 2px 4px 4px rgb(0, 0, 0.644);
  } */
  
  
  
  .upload{
    width: 140px;
    position: relative;
    /* margin: auto;
    text-align: center; */
    right: 38px;
    top: -53px;
  }
  #pp{
    display: none;
  }
  
  .upload img{
    border-radius: 50%;
    border: 4px solid #4941A6;
    width: 125px;
    height: 125px;
  }
  .upload .rightRound{
    position: absolute;
    bottom: 0;
    right: 0;
    background: #00B4FF;
    width: 32px;
    height: 32px;
    line-height: 33px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  .upload .leftRound{
    position: absolute;
    bottom: 0;
    left: 0;
    background: red;
    width: 32px;
    height: 32px;
    line-height: 33px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  .upload .fa{
    color: white;
  }
  .upload input{
    position: absolute;
    transform: scale(2);
    opacity: 0;
  }
  .upload input::-webkit-file-upload-button, .upload input[type=submit]{
    cursor: pointer;
  }

  .tabelstudent {
    margin: auto;
    width: 100%;
    max-width: 100%; /* 891px */
    direction: rtl;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0 0.5rem; /* 10px */
  }
  
 .tabelstudent th,
  td {
    padding: 0;
    height: 1.9rem; /* 30px */
  }
  
.tabelstudent  tbody tr:nth-child(odd) {
    background-color: #1d195d;
  }
  
.tabelstudent  tr:nth-child(even) {
    background-color: #090631;
  }
  

  /* Apply border-radius to the entire row by targeting the first and last cells */
  .tabelstudent tbody tr td:first-child {
    border-top-left-radius: 0rem; /* 8px */
    border-bottom-left-radius: 0rem; /* 0px */
    border-top-right-radius: 1rem; /* 0px */
    border-bottom-right-radius: 1rem; /* 0px */
    margin: 0 0.625rem; /* 10px */
    width: 3.5rem;
  }
  
  .tabelstudent tbody tr td:last-child {
    border-top-left-radius: 1rem; /* 8px */
    border-bottom-left-radius: 1rem; /* 0px */
    border-top-right-radius: 0; /* 0px */
    border-bottom-right-radius: 0; /* 0px */
    width: 7.5rem;
    margin: 0 0.625rem; /* 10px */
  }
  
  /* Responsive design for all screen sizes */
  