@media (min-width: 350px) and (max-width: 550px){
    .third_wraber_div{
        transform: translate(29px, -3px) !important;
    }
    }




@media (max-width: 599px){
   
    .headerOfAllOpen{
        font-size: 12px !important;
        margin-right: 12px !important;
    }

}
@media (min-width: 350px) and (max-width: 430px){
   
    .headerOfAllOpen{
       
    font-size: 12px !important;
    margin-right: 12px !important;
    }


}
@media (min-width: 340px) and (max-width: 443px){
   
    .headerOfAllOpen{
       
    font-size: 11px !important;
    margin-right: 12px !important;
    }


}
