questionContainer {
  width: 100%;
  height: 100%;
}

.question {
  height: 80% !important;
}
.imag-question .inner-image-question img {
  margin-left: 13px !important;
}

@media (max-width: 620px) {
  .imag-question {
    margin: auto;
    width: 100%;
  }

  .inner-image-question {
    width: 100%;
  }

  .inner-image-question p {
    margin-right: 3px;
  }

  .info-button {
    width: 100%;
  }
}

.imag-question {
  height: 100px;
}

.imag-question .inner-image-question img {
  width: 20px;
  margin-left: 5px;
}

.imag-question .inner-image-question p {
  font-weight: 700;
  font-size: 24px;
}
