@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

.image-container_l {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 0;
    /* height: 100%; */
    height: 100vh;

}

.image-container2_l {
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100vh;

}

.image-container2_l img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-text_l, .image-text2_l {
    position: absolute;
    color: black;
    font-weight: bold;
    font-family: 'Cairo', sans-serif;
    text-align: center;
    direction: rtl;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
}

.image-text_l {
    top: 5%;
    font-family: Cairo;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.image-text2_l {
    top: 15%;
    font-family: Cairo;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 768px) {
    .image-text_l {
        font-size: 24px; /* تقليل حجم الخط للنص الرئيسي */
    }

    .image-text2_l {
        font-size: 14px; /* تقليل حجم الخط للنص الفرعي */
    }

    .image-container_l {
        padding: 10px; /* تعديل الحشو لجعل المحتوى مناسبًا لشاشة الهواتف */
    }

    .image-container2_l {
        height: auto; /* السماح للارتفاع بالتكيف مع المحتوى */
    }

    .image-container2_l img {
        height: auto; /* السماح للصورة بالتكيف مع العرض */
    }
}